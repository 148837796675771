import './App.css';
import { useState, useContext } from 'react';
import { Routes, Route, Link } from "react-router-dom";
import { useCookies } from 'react-cookie';

import Home from './Home';
import OauthCallback from './OauthCallback';
import MainLayout from './layout/MainLayout';

import UserContext from './UserContext';

const AppRoutes = () => (
  <Routes >
      <Route index element={ <Home/> }/>
      <Route path="oauthcallback" element={<OauthCallback/>}/>
  </Routes>
);

function App(){ 

  const [cookies] = useCookies();
  const [user, setUser] = useState(cookies.token ? {token: cookies.token} : null);

  return (
    <div> 
      <UserContext.Provider value={{
      user,
      setUser
    }}>
        
          <AppRoutes />
        
      </UserContext.Provider>
    </div>
  );

}

export default App;
