import React, { useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import axios from 'axios';
import { useCookies } from "react-cookie";
import UserContext from './UserContext';

function OauthCallback() {

    const [searchParams] = useSearchParams();
    const { user, setUser }= useContext(UserContext);
    const [ , setCookie, removeCookie ] = useCookies();

    async function validateSession(){

      
      //console.log('setting user');
      setCookie('token', 'xxxxxxxxxxxx');
      removeCookie('access_token');
      //parse the returned access token, get the expiration, and set a cookie so we can track the session timeout
      
      //window.location.replace('/');

    }

    useEffect( ()  => {
        let code = searchParams.get('code');
        validateSession();
      }, [user]);

    return (
      <div>
            OauthCallback {user && user.token}
      </div>
    );
  }
  
  export default OauthCallback;