import React, { useState, useEffect } from "react";
import { useContext} from 'react';
import UserContext from './UserContext';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

function Home() {

    const [hostedUi, setHostedUi] = useState('');
    const { user, setUser }= useContext(UserContext);

    async function validateSession(){

      //check if a cookie exists with an expiration, if not then we definitely are not authenticated
      let resp = await axios.get(`${baseUrl}/auth-validation`,{ withCredentials: true });
      let auth = resp.data;
      if(auth != null){
        setUser(auth)
      }
      console.log(resp);

    }

    useEffect(() => { 

        validateSession();
        let hostedUi = process.env.REACT_APP_COGNITO_HOSTED_UI;
        //window.location.replace(hostedUi);
        setHostedUi(hostedUi);

      }, []);

    return (
      <div>
            {user ? (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <a href={hostedUi} className="nav-link">
                  LogOut
                </a>
              </li>
            </div>
          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <a href={hostedUi}>Login</a>
              </li>
            </div>
          )}
      </div>
    );
  }
  
  export default Home;